import React, {useEffect, useRef, useState} from 'react';
import MyText from "../../components/UI/Text/MyText";
import logo from "../../images/logo.png";
import GreyButton from "../../components/UI/Buttons/GreyButton";
import BlueButton from "../../components/UI/Buttons/BlueButton";
import OrangeButton from "../../components/UI/Buttons/OrangeButton";
import GreenButton from "../../components/UI/Buttons/GreenButton";
import {
    CloudDownloadOutlined,
    DeleteOutlined,
    EditOutlined,
    FormOutlined,
    RetweetOutlined,
    StockOutlined
} from "@ant-design/icons";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import cl from './home.module.css';
import RegionService from "../../services/RegionService";
import RecordService from "../../services/RecordService";
import FormItem from "antd/es/form/FormItem";
import countries from '../../constants/transformed_countries.json';
import {useDraggable} from "react-use-draggable-scroll";
import {clrs} from "../../constants/colors";
import IntlMessage from "../../components/IntlMessage/IntlMessage";
import moment from "moment";
import {Form, message, Flex, Select, Image, Input, Tooltip, Button, DatePicker} from "antd";
const HomePage = () => {
    const navigate = useNavigate();
    const {user, isLoading} = useSelector(state => state.user);
    const dispatch = useDispatch();

    const [records, setRecords] = useState([]);

    const [regions, setRegions] = useState([]);
    const [govs, setGovs] = useState([]);

    const [form] = Form.useForm();

    const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
    const { events } = useDraggable(ref); // Now we pass the reference to the useDraggable hook:


    useEffect(() => {
        init();
    }, []);

    async function init() {
        await RegionService.getRegions()
            .then((r) => {
                setRegions(r.data);
            })
            .catch((r) => {

            })
        await RecordService.getRecords()
            .then((r) => {
                setRecords(r.data);
            })
            .catch((r) => {

            })
    }

    async function handleAddRecord() {
        await RecordService.createRecord()
            .then((r) => {
                setRecords([
                    ...records,
                    r.data
                ])
                window.scrollTo({
                    top: document.documentElement.scrollHeight,
                    behavior: "smooth",
                });
            })
            .catch(() => {

            })
    }

    function getRegionsList() {
        return regions.map(region => {
            return {
                value: region.id,
                label: region.name
            }
        })
    }

    async function handleDeleteRecord(id) {
        await RecordService.deleteRecordById(id)
            .then((r) => {
                setRecords(
                    records.filter(record => record.id !== id)
                )
            })
            .catch((r) => {

            })
    }

    async function handleUpdateRecord(id){

        const regionId = form.getFieldValue(id + "-region");
        const governmentId = form.getFieldValue(id + "-gov");
        const applicationNumber = form.getFieldValue(id + "-applicationNumber");
        const fullName = form.getFieldValue(id + "-fullName");
        const docType = form.getFieldValue(id + "-docType");
        const educationOrganization = form.getFieldValue(id + "-educationOrganization");
        const country = form.getFieldValue(id + "-country");
        const address = form.getFieldValue(id + "-address");
        const iin = form.getFieldValue(id + "-iin");
        const receipt = form.getFieldValue(id + "-receipt");
        const paymentDate = form.getFieldValue(id + "-paymentDate");
        const sendDate = form.getFieldValue(id + "-sendDate");
        const acceptDate = form.getFieldValue(id + "-acceptDate");
        const planDate = form.getFieldValue(id + "-planDate");
        const status = form.getFieldValue(id + "-status");
        const rejectComment = form.getFieldValue(id + "-rejectComment");

        let recordUpdateRequest = {
            regionId: regionId ? regionId : null,
            governmentId: governmentId ? governmentId : null,
            applicationNumber: applicationNumber ? applicationNumber : null,
            fullName: fullName ? fullName : null,
            docType: docType ? docType : null,
            educationOrganization: educationOrganization ? educationOrganization : null,
            country: country ? country : null,
            address: address ? address : null,
            iin: iin ? iin : null,
            receipt: receipt ? receipt : null,
            sendDate: sendDate ? new Date(sendDate) : null,
            paymentDate: paymentDate ? new Date(paymentDate) : null,
            acceptDate: acceptDate ? new Date(acceptDate) : null,
            planDate: planDate ? new Date(planDate) : null,
            status: status ? status : null,
            rejectComment: rejectComment ? rejectComment : null
        }
        message.loading(<IntlMessage id={'loading'}/>, 0);
        await RecordService.updateRecordById(id, recordUpdateRequest)
            .then((r) => {
                message.destroy();
                message.success(<IntlMessage id={'success'}/>, 5);
                setRecords(records.map(record => {
                    if (r.data.id === record.id) {
                        return r.data;
                    }
                    return record;
                }))
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            })
    }

    function triggerDownload(blob, filename) {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); // Set the download attribute with a filename
        document.body.appendChild(link);
        link.click();

        // Clean up and revoke the URL after download
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }

    async function handleDownloadList() {
        message.loading(<IntlMessage id={'loading'}/>, 0);
        await RecordService.downloadList()
            .then((r) => {
                message.destroy();
                message.success(<IntlMessage id={'success'}/>, 5);

                triggerDownload(r.data, "список.xlsx")
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            })
    }

    if (user == null && !isLoading) {
        navigate('/login');
    }

    return (
        <Flex style={{width: "100%", position: "relative", paddingLeft: 15, paddingRight: 15}} vertical align={"center"} justify={"flex-start"} gap={50}>
            <Flex style={{width: "100%", maxWidth: 1920, paddingTop: 35}} vertical align={"center"} justify={"flex-start"} gap={30}>
                <Flex align={"center"} justify={"center"} gap={25}>
                    <Image preview={false} src={logo} width={75}/>
                    <Flex vertical gap={0} align={"flex-start"} justify={"flex-start"}>
                        <MyText strong style={{textAlign: "center", fontSize: "1.2rem"}}>
                            Информационная база апостилирования официальных документов, исходящих из организаций образования
                        </MyText>
                    </Flex>
                </Flex>
                <Flex style={{width: "100%"}} align={"center"} justify={"flex-start"} gap={5}>
                    <GreenButton onClick={handleAddRecord}>
                        <FormOutlined/>
                        <span> </span>
                        Добавить запись
                    </GreenButton>
                    <Link to={'/stats'}>
                        <OrangeButton>
                            <StockOutlined/>
                            <span> </span>
                            Статистика
                        </OrangeButton>
                    </Link>

                    <BlueButton onClick={handleDownloadList}>
                        <CloudDownloadOutlined />
                        <span> </span>
                        Загрузить
                    </BlueButton>
                </Flex>
                <Flex style={{width: "100%", overflow: "scroll"}} ref={ref} {...events} align={"center"} justify={"flex-start"}>
                    <Form style={{width: "100%"}} form={form}>
                        <table className={cl.table}>
                            <thead>
                            <tr className={cl.tr}>
                                <th className={cl.th}>№</th>
                                <th className={cl.th} style={{width: 200}}>Регион</th>
                                <th className={cl.th} style={{width: 300}}>ЦОН</th>
                                <th className={cl.th} style={{width: 125}}>№ заявки</th>
                                <th className={cl.th} style={{width: 125}}>ФИО услугополучателя</th>
                                <th className={cl.th} style={{width: 125}}>Вид документа</th>
                                <th className={cl.th} style={{width: 125}}>Организация образования</th>
                                <th className={cl.th} style={{width: 200}}>Страна выезда</th>
                                <th className={cl.th} style={{width: 125}}>Адрес проживания</th>
                                <th className={cl.th} style={{width: 125}}>ИИН плательщика</th>
                                <th className={cl.th} style={{width: 125}}>Квитанция</th>
                                <th className={cl.th} style={{width: 125}}>Дата платежа</th>
                                <th className={cl.th} style={{width: 125}}>Дата подачи в ЦОН</th>
                                <th className={cl.th} style={{width: 125}}>Дата приема в МНВО</th>
                                <th className={cl.th} style={{width: 125}}>Плановая дата исполнения</th>
                                <th className={cl.th} style={{width: 125}}>Статус</th>
                                <th className={cl.th} style={{width: 125}}>Основание для отказа</th>
                                <th className={cl.th} style={{width: 125}}>Действия</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                records.map((record, i) => {
                                    return (
                                        <RegionGovItem
                                            key={new Date() + i + "RegionGovItem"}
                                            form={form}
                                            regions={regions}
                                            getRegionsList={getRegionsList}
                                            handleUpdateRecord={handleUpdateRecord}
                                            i={i}
                                            handleDeleteRecord={handleDeleteRecord}
                                            record={record}
                                        />
                                    )
                                })
                            }

                            </tbody>
                        </table>
                    </Form>
                </Flex>

            </Flex>

        </Flex>
    );
};

const RegionGov = ({ record, form, regions, getRegionsList }) => {
    const [govsList, setGovsList] = useState([]);

    const getGovsList = (regionId) => {
        const region = regions.find(region => region.id === regionId);
        if (region) {
            const govs = region.governments.map(gov => ({
                value: gov.id,
                label: gov.name
            }));
            setGovsList(govs);
        } else {
            setGovsList([]);
        }
    };

    useEffect(() => {
        if (record.region) {
            getGovsList(record.region.id);
        }

    }, []);

    return (
        <>
            <td className={cl.td}>
                <FormItem style={{ margin: 0, width: 200 }} name={`${record.id}-region`}>
                    <Select
                        defaultValue={record.region?.id}
                        options={getRegionsList()}
                        onChange={(v) => {
                            getGovsList(v);
                            form.setFieldValue(`${record.id}-gov`, null);
                        }}
                        showSearch
                        allowClear
                    />
                </FormItem>
            </td>
            <td className={cl.td}>
                <FormItem style={{ margin: 0, width: 300 }} name={`${record.id}-gov`}>
                    <Select
                        defaultValue={record.government?.id}
                        options={govsList}
                        showSearch
                        allowClear
                    />
                </FormItem>
            </td>
        </>
    );
};

const RegionGovItem = ({record, form, regions, getRegionsList, i, handleDeleteRecord, handleUpdateRecord}) => {

    const [isEdit, setEdit] = useState(false);

    if (isEdit) {
        return (
            <tr className={cl.tr}>
                <td className={cl.td}>{i + 1}</td>
                <RegionGov record={record} form={form} regions={regions} getRegionsList={getRegionsList}/>
                <td className={cl.td}>
                    <FormItem style={{margin: 0, width: 125}} name={record.id + "-applicationNumber"}>
                        <Input defaultValue={record.applicationNumber}/>
                    </FormItem>
                </td>
                <td className={cl.td}>
                    <FormItem style={{margin: 0, width: 125}} name={record.id + "-fullName"}>
                        <Input defaultValue={record.fullName}/>
                    </FormItem>
                </td>
                <td className={cl.td}>
                    <FormItem style={{margin: 0, width: 125}} name={record.id + "-docType"}>
                        <Input defaultValue={record.docType}/>
                    </FormItem>
                </td>
                <td className={cl.td}>
                    <FormItem style={{margin: 0, width: 125}} name={record.id + "-educationOrganization"}>
                        <Input defaultValue={record.educationOrganization}/>
                    </FormItem>
                </td>
                <td className={cl.td}>
                    <FormItem style={{margin: 0, width: 200}} name={record.id + "-country"}>
                        <Select defaultValue={record.country} options={countries} showSearch allowClear/>
                    </FormItem>
                </td>
                <td className={cl.td}>
                    <FormItem style={{margin: 0, width: 125}} name={record.id + "-address"}>
                        <Input defaultValue={record.address}/>
                    </FormItem>
                </td>
                <td className={cl.td}>
                    <FormItem style={{margin: 0, width: 125}} name={record.id + "-iin"}>
                        <Input defaultValue={record.iin}/>
                    </FormItem>
                </td>
                <td className={cl.td}>
                    <FormItem style={{margin: 0, width: 125}} name={record.id + "-receipt"}>
                        <Input defaultValue={record.receipt}/>
                    </FormItem>
                </td>
                <td className={cl.td}>
                    <FormItem style={{margin: 0, width: 125}}
                              name={`${record.id}-paymentDate`}>
                        <Input defaultValue={record.paymentDate} type={'date'}/>
                    </FormItem>
                </td>
                <td className={cl.td}>
                    <FormItem style={{margin: 0, width: 125}}
                              name={`${record.id}-sendDate`}>
                        <Input defaultValue={record.sendDate} type={'date'}/>
                    </FormItem>
                </td>
                <td className={cl.td}>
                    <FormItem style={{margin: 0, width: 125}}
                              name={`${record.id}-acceptDate`}>
                        <Input defaultValue={record.acceptDate} type={'date'}/>
                    </FormItem>
                </td>
                <td className={cl.td}>
                    <FormItem style={{margin: 0, width: 125}}
                              name={`${record.id}-planDate`}>
                        <Input defaultValue={record.planDate} type={'date'}/>
                    </FormItem>
                </td>
                <td className={cl.td}>
                    <FormItem style={{margin: 0, width: 125}} name={record.id + "-status"}>
                        <Input defaultValue={record.status}/>
                    </FormItem>
                </td>
                <td className={cl.td}>
                    <FormItem style={{margin: 0, width: 125}} name={record.id + "-rejectComment"}>
                        <Input defaultValue={record.rejectComment}/>
                    </FormItem>
                </td>
                <td className={cl.td}>
                    <Flex style={{width: "100%"}} align={"center"} justify={"center"} gap={10}>
                        <Tooltip title={<IntlMessage id={'save'}/>}>
                            <Button size={"small"} type={"primary"} onClick={() => {
                                handleUpdateRecord(record.id)
                                setEdit(false);
                            }}>
                                <RetweetOutlined/>
                            </Button>
                        </Tooltip>
                        <Tooltip title={<IntlMessage id={'delete'}/>}>
                            <Button size={"small"} danger onClick={() => {
                                handleDeleteRecord(record.id)
                            }}>
                                <DeleteOutlined/>
                            </Button>
                        </Tooltip>


                    </Flex>

                </td>
            </tr>
        )
    }

    return (
        <tr className={cl.tr}>
            <td className={cl.td}>{i + 1}</td>
            <td className={cl.td}>
                <MyText size={"small"}>
                    {record.region?.name}
                </MyText>
            </td>
            <td className={cl.td}>
                <MyText size={"small"}>
                    {record.government?.name}
                </MyText>
            </td>
            <td className={cl.td}>
                <MyText size={"small"}>
                    {record.applicationNumber}
                </MyText>
            </td>
            <td className={cl.td}>
                <MyText size={"small"}>
                    {record.fullName}
                </MyText>
            </td>
            <td className={cl.td}>
                <MyText size={"small"}>
                    {record.docType}
                </MyText>
            </td>
            <td className={cl.td}>
                <MyText size={"small"}>
                    {record.educationOrganization}
                </MyText>
            </td>
            <td className={cl.td}>
                <MyText size={"small"}>
                    {record.country}
                </MyText>
            </td>
            <td className={cl.td}>
                <MyText size={"small"}>
                    {record.address}
                </MyText>
            </td>
            <td className={cl.td}>
                <MyText size={"small"}>
                    {record.iin}
                </MyText>
            </td>
            <td className={cl.td}>
                <MyText size={"small"}>
                    {record.receipt}
                </MyText>
            </td>
            <td className={cl.td}>
                <MyText size={"small"}>
                    {record.paymentDate && new Date(record.paymentDate).toLocaleDateString()}
                </MyText>
            </td>
            <td className={cl.td}>
                <MyText size={"small"}>
                    {record.sendDate && new Date(record.sendDate).toLocaleDateString()}
                </MyText>
            </td>
            <td className={cl.td}>
                <MyText size={"small"}>
                    {record.acceptDate && new Date(record.acceptDate).toLocaleDateString()}
                </MyText>
            </td>
            <td className={cl.td}>
                <MyText size={"small"}>
                    {record.planDate && new Date(record.planDate).toLocaleDateString()}
                </MyText>
            </td>
            <td className={cl.td}>
                <MyText size={"small"}>
                    {record.status}
                </MyText>
            </td>
            <td className={cl.td}>
                <MyText size={"small"}>
                    {record.rejectComment}
                </MyText>
            </td>
            <td className={cl.td}>
                <Flex style={{width: "100%"}} align={"center"} justify={"center"} gap={10}>
                    <Tooltip title={<IntlMessage id={'edit'}/>}>
                        <Button size={"small"} type={"primary"} onClick={() => {
                            setEdit(true)
                        }}>
                            <EditOutlined/>
                        </Button>
                    </Tooltip>
                    <Tooltip title={<IntlMessage id={'delete'}/>}>
                        <Button size={"small"} danger onClick={() => {
                            handleDeleteRecord(record.id)
                        }}>
                            <DeleteOutlined/>
                        </Button>
                    </Tooltip>


                </Flex>

            </td>
        </tr>
    )
}
export default HomePage;